(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  var navigation = $('.navbar-trigger');
  var collapse = $('.header-collapse');
  navigation.click(function (e) {
    e.preventDefault();
    $(this).toggleClass('collapsed');
    collapse.slideToggle('fade');
  });

  if ($('.common-relative').length > 0) {
    // Cache selectors
    var lastId,
        topMenu = $("header"),
        topMenuHeight = topMenu.outerHeight(),
        // All list items
    menuItems = topMenu.find(".header-wrapper__nav ul li a.hash-url"),
        // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));

      if (item.length) {
        return item;
      }
    }); // Bind click handler to menu items
    // so we can get a fancy scroll animation

    menuItems.click(function (e) {
      var href = $(this).attr("href"),
          offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
      menuItems.removeClass('active');
      $(this).addClass('active');
      $('.header-collapse').removeAttr('style');
      $('.navbar-trigger').addClass('collapsed');
      $('html, body').stop().animate({
        scrollTop: offsetTop
      }, 700);
      e.preventDefault();
    }); // Bind to scroll

    $(window).scroll(function () {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight; // Get id of current scroll item

      var cur = scrollItems.map(function () {
        if ($(this).offset().top <= fromTop) return this;
      }); // Get the id of the current element

      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : "";

      if (lastId !== id) {
        lastId = id; // Set/remove active class

        menuItems.parent().removeClass("active").end().filter("[href='#" + id + "']").parent().addClass("active");
      }
    });
  }

  var menu = ['QR AVI', 'TOKEN FESTİVALİ', 'İNDİRİMLİ ÖN SATIŞ', 'ÖN SATIŞ', 'ARZ KOŞULLARI'];
  var swiper = new Swiper(".supply-calendar .swiper", {
    speed: 800,
    autoHeight: true,
    //effect: 'fade',
    pagination: {
      el: ".supply-calendar .swiper-pagination",
      clickable: true,
      renderBullet: function renderBullet(index, className) {
        return '<span class="' + className + '">' + menu[index] + "</span>";
      }
    },
    navigation: {
      nextEl: ".supply-calendar .swiper-button-next",
      prevEl: ".supply-calendar .swiper-button-prev"
    }
  }); // var operatingSystem, userAgentString = navigator.userAgent;
  // var link = $("#store");
  // if (userAgentString.indexOf("iPhone") > -1 || userAgentString.indexOf("iPod") > -1 || userAgentString.indexOf("iPad") > -1) {
  //     operatingSystem = "iOS";
  //     link.attr("href", "https://apps.apple.com/tr/app/bitexensahada/id1603985409");
  // } else if (/Android/.test(userAgentString)) {
  //     operatingSystem = "Android";
  //     link.attr("href", "https://play.google.com/store/apps/details?id=com.exenpay.bitexensahada");
  // }
  // } else if (/Windows Phone/.test(userAgentString)) {
  //     operatingSystem = "Windows Phone";
  //     link.attr("href", "http://www.windowsphone.com/en-us/store");
  // }

  var _popup = Cookies.get("popup");

  if (window.location.pathname == '/') {
    if (_popup != "true") {
      $.fancybox.open({
        src: '/assets/ajax/popup.html',
        type: 'ajax',
        height: 700,
        autoSize: true,
        afterClose: function afterClose() {
          Cookies.set("popup", "true", {
            expires: 1
          });
        }
      });
    }
  } //fix popup
  // $.fancybox.open({
  //     src:'/assets/ajax/popup.html',
  //     type:'ajax',
  //     autoSize: true
  // });


  var swiper1 = new Swiper(".usage-areas .swiper", {
    slidesPerView: 4,
    spaceBetween: 0,
    speed: 700,
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      610: {
        slidesPerView: 2
      },
      991: {
        slidesPerView: 4
      }
    }
  });
  var swiper2 = new Swiper(".sss-section .swiper", {
    slidesPerView: 2,
    spaceBetween: 30,
    speed: 700,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 24
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    },
    navigation: {
      nextEl: ".sss-section .swiper-button-next",
      prevEl: ".sss-section .swiper-button-prev"
    }
  });
  var swiper3 = new Swiper(".partners-section .swiper", {
    slidesPerView: 2,
    spaceBetween: 30,
    speed: 700,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 24
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    },
    navigation: {
      nextEl: ".partners-section .swiper-button-next",
      prevEl: ".partners-section .swiper-button-prev"
    }
  });
  var swiper4 = new Swiper(".road-map .swiper", {
    direction: "horizontal",
    slidesPerView: "auto",
    freeMode: true
    /*scrollbar: {
        //el: ".swiper-scrollbar",
        draggable: true,
        snapOnRelease:false
    },*/
    //mousewheel: true,

  });
});

},{}]},{},[1]);
